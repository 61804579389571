import React from "react"
import styled from "styled-components"

const CompLead = ({ children }) => {
  return <Lead>{children}</Lead>
}

export default CompLead

const Lead = styled.p`
  text-align: center;
  font-size: 1.25rem;
  font-weight: 500;

  @media (max-width: 500px) {
    br {
      display: none;
    }
  }
`
