import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import Page from "../components/page"
import Wrap from "../components/wrap"
import Lead from "../components/lead"

const PageInstructors = ({ data }) => {
  const instructors = data.instructors.edges.map(i => {
    return (
      <li key={i.node.id}>
        <Link to={`/${i.node.slug}`} className="link">
          <Img fluid={i.node.frontmatter.photo.childImageSharp.fluid} />
          <span
            className={[
              "name",
              i.node.frontmatter.name.length > 18 ? "name-long" : "",
            ].join(" ")}
          >
            {i.node.frontmatter.name}
          </span>
        </Link>
      </li>
    )
  })

  return (
    <Page title="経験豊富な講師陣">
      <Wrap>
        <Lead>
          TryHard DJ Academyでは第一線で活躍中の講師と
          <br />
          各コース・各ジャンルに特化したカリキュラムを学べます
        </Lead>
        <Instructors>
          <ul>{instructors}</ul>
        </Instructors>
      </Wrap>
    </Page>
  )
}

export default PageInstructors

export const pageQuery = graphql`
  query {
    instructors: allMdx(
      filter: { fields: { sourceInstanceName: { eq: "instructors" } } }
      sort: { fields: frontmatter___sort, order: ASC }
    ) {
      edges {
        node {
          id
          slug
          frontmatter {
            name
            photo {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

const Instructors = styled.div`
  margin: 2rem auto;
  padding: 2rem 0;

  ul {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    width: calc(100% + 1.5rem);
    margin: 0 0 0 -0.75rem;
    padding: 0;
    list-style: none;

    @media (max-width: 600px) {
      width: calc(100% + 1rem);
      margin: 0 0 0 -0.5rem;
    }
  }

  li {
    width: calc((100% - 7.5rem) / 5);
    margin: 0 0.75rem 1.5rem;

    @media (max-width: 768px) {
      width: calc((100% - 6rem) / 4);
    }

    @media (max-width: 600px) {
      width: calc((100% - 3rem) / 3);
      margin: 0 0.5rem 1rem;
    }

    @media (max-width: 414px) {
      width: calc((100% - 2rem) / 2);
    }
  }

  .link {
    position: relative;
    display: block;
    color: black;
    text-decoration: none;
  }

  .name {
    position: absolute;
    z-index: 2;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 0.25rem;
    text-align: center;
    font-size: 0.875rem;
    background: rgba(255, 255, 255, 0.7);

    &.name-long {
      font-size: 0.75rem;
    }
  }
`
